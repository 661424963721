export const ROUTES = {
  about: '/about-us',
  contact: '/contact-us',
  services: '/services',
  trackIt: '/track-it',

  admin: {
    index: '/admin',
    login: '/admin/login',
    add_tracking: '/admin/tracking',
    settings: '/admin/settings',
  },
}

export const SERVICES = [
  'Air Freight',
  'Ocean Freight',
  'Cargo Transportation',
  'Packaging and Storage',
]

export const TRACKING_STATUS = {
  Active: 'Active',
  Inactive: 'Inactive',
  'Picked Up': 'Picked Up',
  Arrived: 'Arrived',
  Delivered: 'Delivered',
  'On hold': 'On hold',
}

export const API_URL = 'https://whales-be.herokuapp.com'
